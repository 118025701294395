<template>
  <div class="home" id="test">
    <div class="stage-bg"></div>
    <div class="border">
      <div class="stage">
        <h2>Rückantwort</h2>

        <div class="stage-input-cont">
          <v-layout v-if="error">
            <v-flex>
              <app-alert
                @dismissed="onDismissed"
                :text="this.error"
              ></app-alert>
            </v-flex>
          </v-layout>

          <v-text-field
            v-model="code"
            label="Bitte Code eingeben"
            required
            type="number"
            class="input-stage"
            @keyup.enter="checkCode"
            rounded
            dense
          ></v-text-field>
          <v-btn rounded @click="checkCode" :disabled="loading" :loading="loading" dense color="primary">
            Weiter geht's!
          </v-btn>
        </div>

        <h1>Lisa & Thomas</h1>
      </div>
    </div>

    <footer>
      <p>
        <strong>&copy; 2021 lisa-und-tommy.de</strong> - all rights reserved!
        <br /><span class="small"
          >Konzept, Design und Entwicklung -
          <a href="https://bymarc.de">bymarc.media</a>
        </span>
      </p>
      <p class="links">
        <a href="Impressum">Impressum</a> |
        <a href="Datenschutz">Datenschutz</a>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Theme",

  data() {
    return {
      code: "",

      rules: {
        required: (value) => !!value || "Required.",
        number: [
          (v) => /^[0-9]{6,6}$/.test(v) || "Bitte sechsstelligen Code eingeben!",
        ],
      },

    };
  },
  methods: {
    async checkCode() {
      if (this.IsCodeEntered) {
        this.$store.dispatch("checkCode", { code: this.code });
      }
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
  },
  computed: {
    error() {
      return this.$store.getters.getError;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
    IsCodeEntered() {
      return this.code.length > 0;
    },
  },

  components: {},
};
</script>
<style lang="scss">
// @import url("../styles/global.scss");
</style>
